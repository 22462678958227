import _detectHover3 from "detect-hover";
import _detectPointer3 from "detect-pointer";
import _detectTouchEvents3 from "detect-touch-events";
import _detectPassiveEvents3 from "detect-passive-events";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _detectHover = _detectHover3;

var _detectHover2 = _interopRequireDefault(_detectHover);

var _detectPointer = _detectPointer3;

var _detectPointer2 = _interopRequireDefault(_detectPointer);

var _detectTouchEvents = _detectTouchEvents3;

var _detectTouchEvents2 = _interopRequireDefault(_detectTouchEvents);

var _detectPassiveEvents = _detectPassiveEvents3;

var _detectPassiveEvents2 = _interopRequireDefault(_detectPassiveEvents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/*
 * detectIt object structure
 * const detectIt = {
 *   deviceType: 'mouseOnly' / 'touchOnly' / 'hybrid',
 *   passiveEvents: boolean,
 *   hasTouch: boolean,
 *   hasMouse: boolean,
 *   maxTouchPoints: number,
 *   primaryHover: 'hover' / 'none',
 *   primaryPointer: 'fine' / 'coarse' / 'none',
 *   state: {
 *     detectHover,
 *     detectPointer,
 *     detectTouchEvents,
 *     detectPassiveEvents,
 *   },
 *   update() {...},
 * }
 */


function determineDeviceType(hasTouch, anyHover, anyFine, state) {
  // A hybrid device is one that both hasTouch and any input device can hover
  // or has a fine pointer.
  if (hasTouch && (anyHover || anyFine)) return "hybrid"; // workaround for browsers that have the touch events api,
  // and have implemented Level 4 media queries but not the
  // hover and pointer media queries, so the tests are all false (notable Firefox)
  // if it hasTouch, no pointer and hover support, and on an android assume it's touchOnly
  // if it hasTouch, no pointer and hover support, and not on an android assume it's a hybrid

  if (hasTouch && Object.keys(state.detectHover).filter(function (key) {
    return key !== "update";
  }).every(function (key) {
    return state.detectHover[key] === false;
  }) && Object.keys(state.detectPointer).filter(function (key) {
    return key !== "update";
  }).every(function (key) {
    return state.detectPointer[key] === false;
  })) {
    if (window.navigator && /android/.test(window.navigator.userAgent.toLowerCase())) {
      return "touchOnly";
    }

    return "hybrid";
  } // In almost all cases a device that doesn’t support touch will have a mouse,
  // but there may be rare exceptions. Note that it doesn’t work to do additional tests
  // based on hover and pointer media queries as older browsers don’t support these.
  // Essentially, 'mouseOnly' is the default.


  return hasTouch ? "touchOnly" : "mouseOnly";
}

var detectIt = {
  state: {
    detectHover: _detectHover2.default,
    detectPointer: _detectPointer2.default,
    detectTouchEvents: _detectTouchEvents2.default,
    detectPassiveEvents: _detectPassiveEvents2.default
  },
  update: function update() {
    detectIt.state.detectHover.update();
    detectIt.state.detectPointer.update();
    detectIt.state.detectTouchEvents.update();
    detectIt.state.detectPassiveEvents.update();
    detectIt.updateOnlyOwnProperties();
  },
  updateOnlyOwnProperties: function updateOnlyOwnProperties() {
    if (typeof window !== "undefined") {
      detectIt.passiveEvents = detectIt.state.detectPassiveEvents.hasSupport || false;
      detectIt.hasTouch = detectIt.state.detectTouchEvents.hasSupport || false;
      detectIt.deviceType = determineDeviceType(detectIt.hasTouch, detectIt.state.detectHover.anyHover, detectIt.state.detectPointer.anyFine, detectIt.state);
      detectIt.hasMouse = detectIt.deviceType !== "touchOnly";
      detectIt.primaryInput = detectIt.deviceType === "mouseOnly" && "mouse" || detectIt.deviceType === "touchOnly" && "touch" || // deviceType is hybrid:
      detectIt.state.detectHover.hover && "mouse" || detectIt.state.detectHover.none && "touch" || // if there's no support for hover media queries but detectIt determined it's
      // a hybrid  device, then assume it's a mouse first device
      "mouse"; // issue with Windows Chrome on hybrid devices starting in version 59 where
      // media queries represent a touch only device, so if the browser is an
      // affected Windows Chrome version and hasTouch,
      // then assume it's a hybrid with primaryInput mouse
      // see https://github.com/rafrex/detect-it/issues/8

      var isAffectedWindowsChromeVersion = /windows/.test(window.navigator.userAgent.toLowerCase()) && /chrome/.test(window.navigator.userAgent.toLowerCase()) && parseInt(/Chrome\/([0-9.]+)/.exec(navigator.userAgent)[1], 10) >= 59;

      if (isAffectedWindowsChromeVersion && detectIt.hasTouch) {
        detectIt.deviceType = "hybrid";
        detectIt.hasMouse = true;
        detectIt.primaryInput = "mouse";
      }
    }
  }
};
detectIt.updateOnlyOwnProperties();
exports.default = detectIt;
export default exports;
export const __esModule = exports.__esModule;